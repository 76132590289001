import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// Define the services array with balanced headings and descriptions
const services = [
  { id: 1, title: 'Professional Web Development', description: 'We create SEO-friendly websites that are visually appealing and fully functional, helping your business stand out online.', img: 'Website.jpg' },
  { id: 2, title: 'Mobile App Development ', description: 'We build user-friendly mobile apps for iOS and Android, designed to enhance customer experiences and drive business growth.', img: 'MBD.png' },
  {
    id: 3,
    title: 'Custom Software Development',
    description: 'Our tailored software solutions seamlessly streamline your operations and foster long-term business scalability.',
    img: 'SDD.png'
  },  
];

const Services = ({ pageType }) => {
  const servicesToShow = useMemo(() => {
    return pageType === 'home' ? services.slice(0, 3) : services;
  }, [pageType]);

  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section className="bg-white text-gray-800 py-12 sm:py-16 lg:py-10 lg:pt-28  lg:px-20 px-5" id="services">
      <div className="container mx-auto px-4 sm:px-6 xl:px-8 lg:px-16">
        {/* Main heading */}
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-end mb-8 lg:mb-12 text-center lg:text-left">
          <div className="max-w-2xl mb-6 lg:mb-0">
            <h2 className="text-2xl uppercase font-poppins sm:text-4xl font-semibold text-black mb-4">
              Our Expertise
            </h2>
            <p className="text-gray-600 font-poppins text-base sm:text-base mb-0 lg:mr-28 capitalize">
              Tailored solutions designed to meet your unique business needs.
            </p>
          </div>
          <Link to="/services" className="w-full lg:w-auto">
            <button className="bg-indigo-700 font-poppins  tracking-normal text-white w-full lg:w-auto font-medium text-base sm:text-lg px-6 py-3 rounded-full hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center lg:justify-start">
              Explore Our Services
              <span className="ml-2">→</span>
            </button>
          </Link>
        </div>

        {/* Services Grid */}
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-12"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {servicesToShow.map((service) => (
            <motion.div
              key={service.id}
              variants={childVariants}
              whileHover={{ scale: 1.05 }}
              className="bg-white rounded-3xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl"
            >
              <div className="relative overflow-hidden rounded-t-3xl">
                <img
                  src={service.img}
                  alt={`${service.title} services by Inernett Technology Solution`}
                  className="w-full h-60 object-cover transition-transform duration-300 group-hover:scale-110"
                  loading="lazy"
                  onError={(e) => (e.target.src = '/path/to/fallback.jpg')}
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <Link to={`/services/${service.id}`}>
                    <button
                      className="bg-indigo-700 text-white font-poppins tracking-normal py-3 px-6 rounded-full hover:bg-blue-600 transition-colors duration-300 shadow-md"
                      aria-label={`Learn more about ${service.title}`}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
              {/* Service Title and Description */}
              <div className="p-6 pt-10">
                <h3 className="text-xl font-semibold capitalize font-poppins tracking-normal text-black mb-3">
                  {service.title}
                </h3>
                <p className="text-gray-600 font-poppins capitalize leading-relaxed lg:leading-relaxed text-sm lg:text-base mb-4">
                  {service.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Services;
