import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { project1, project2, project3 } from '../components/eachproject';

const projects = [project1, project2, project3];

const Projects = () => {
  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section className="bg-white font-poppins text-gray-800 py-12 sm:py-16 lg:py-10 lg:pt-28  lg:px-20 px-5" id="projects">
      <div className="container mx-auto px-4 sm:px-6 xl:px-8 lg:px-16">
        {/* Section Heading */}
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-end mb-8 lg:mb-12 text-center lg:text-left">
          <div className="max-w-2xl mb-6 lg:mb-0">
            <h2 className="text-2xl font-poppins uppercase sm:text-4xl font-semibold text-black mb-4">
              Our Products 
            </h2>
            <p className="text-gray-600 text-base font-poppins sm:text-base mb-2 capitalize">
              Bringing innovative ideas to life, one product at a time.
            </p>
          </div>
          <Link to="/products" className="w-full lg:w-auto">
            <button className="bg-indigo-700 font-poppins tracking-normal w-full lg:w-auto text-white font-semibold text-base sm:text-lg px-6 py-3 rounded-full hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center lg:justify-start">
              View All Products
              <span className="ml-2">→</span>
            </button>
          </Link>
        </div>

        {/* Projects Grid */}
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-20"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {projects.map((project, index) => (
            <motion.div
              key={index}
              variants={childVariants}
              whileHover={{ scale: 1.05 }}
              className="bg-white rounded-3xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl"
            >
              {/* Project Image */}
              <div className="relative overflow-hidden rounded-t-3xl">
                <img
                  src={project.img}
                  alt={`${project.title} - ${project.description}`}
                  className="w-full h-60 object-cover transition-transform duration-300 group-hover:scale-110"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-400/20 via-black/10 to-transparent opacity-75 transition-opacity duration-300"></div>
                <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <Link to={`/projects/${project.id}`}>
                    <button
                      className="bg-indigo-700 font-poppins text-white font-bold py-3 px-6 rounded-full hover:bg-indigo-600 transition-colors duration-300 shadow-md"
                      aria-label={`Learn more about ${project.title}`}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>

              {/* Project Title and Description */}
              <div className="p-6">
                <h3 className="text-xl font-poppins tracking-normal font-semibold capitalize text-black mb-3">
                  {project.title}
                </h3>
                <p className="text-gray-600 font-poppins text-sm lg:text-base mb-4">
                  {project.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
