import React from 'react';
import { useParams } from 'react-router-dom';
import { project1, project2, project3, project4, project5, project6 } from '../components/eachproject'; // Adjust as needed

const ProjectDetail = () => {
  const { id } = useParams();
  const project = [project1, project2, project3, project4, project5, project6].find(p => p.id === id);

  if (!project) return <p className="text-center py-20 text-xl">Project not found</p>;

  return (
    <section className="bg-gray-50 font-poppins text-gray-800 py-16 sm:py-24 lg:py-32"> {/* Adjusted top padding for responsiveness */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-20">
        <div className="flex flex-col md:flex-row items-start md:space-x-10 lg:space-x-16">
          
          {/* Image Section */}
          <div className="md:w-1/2 w-full mb-8 md:mb-0 mt-16">
            <div className="relative overflow-hidden rounded-lg shadow-lg">
              <img
                src={project.img}
                alt={project.title}
                className="w-full h-auto object-cover"
              />
            </div>
          </div>

          {/* Text Section */}
          <div className="md:w-1/2 w-full">
            <h1 className="text-3xl sm:text-4xl font-bold mb-6 text-slate-900">{project.title}</h1>

            {/* Project Overview */}
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-600 mb-4">Overview</h2>
            {project.explanation.map((paragraph, index) => (
              <p key={index} className="text-base sm:text-lg mb-4 leading-relaxed lg:leading-loose text-gray-700">
                {paragraph}
              </p>
            ))}

            {/* Key Benefits */}
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-600 mb-4">Key Benefits</h2>
            <ul className="list-disc list-inside mb-6 text-base sm:text-lg text-gray-700">
              {project.benefits.map((benefit, index) => (
                <li key={index} className="mb-2">{benefit}</li>
              ))}
            </ul>

            <a 
              href="/products"
              className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors duration-300"
            >
              Back to Products
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetail;
