import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Hero = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    setIsVisible(true);
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  const scrollToNextSection = () => {
    const nextSection = document.getElementById('next-section');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const slides = [
    {
      backgroundImage: "url('/InerNett1.jpg')",
      title: "Innovate & Elevate",
      text: "Empower your business with cutting-edge software solutions designed for growth and efficiency.",
    },
    {
      backgroundImage: "url('/MobileDesign.jpg')",
      title: "Products & Inspire",
      text: "Delivering mobile app experiences and products that engage users and drive business success.",
    },
    {
      backgroundImage: "url('/DigitalDesign.jpg')",
      title: "Connect & Thrive",
      text: "Amplify your brand’s voice and reach your audience with dynamic digital marketing strategies.",
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 8000); // Change every 8 seconds
    return () => clearInterval(intervalId);
  }, [slides.length]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  return (
    <motion.section
      id="hero-section"
      className="relative font-roboto bg-cover bg-center h-screen flex flex-col justify-center items-center"
      style={{ backgroundImage: slides[currentIndex].backgroundImage }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className="absolute inset-0 bg-gradient-to-b from-black/50 to-black/60 flex flex-col justify-center items-center text-center text-white px-4 sm:px-8 md:px-16 lg:px-32 lg:pt-20"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          transition={{ duration: 0.8, delay: 0.2 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-tight mb-4 lg:mb-5 text-shadow-lg lg:px-20 uppercase font-poppins"
        >
          {slides[currentIndex].title}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          transition={{ duration: 0.8, delay: 0.4 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="text-sm sm:text-base font-poppins tracking-wide md:text-lg lg:text-lg font-light mb-8 lg:mb-5 lg:px-28 px-10 lg:leading-relaxed leading-loose max-w-xl sm:max-w-2xl md:max-w-3xl text-shadow-md capitalize"
        >
          {slides[currentIndex].text}
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row"
        >
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="bg-[#FFFC31] text-black font-poppins tracking-normal uppercase px-6 sm:px-9 py-3 sm:py-4 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 text-sm sm:text-base"
            aria-label="Get Started"
            onClick={scrollToNextSection}
          >
            Get Started
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white text-gray-700 px-6 uppercase sm:px-9 font-poppins tracking-normal py-3 sm:py-4 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 text-sm sm:text-base"
            aria-label="Services"
          >
            <Link to="/services">Our Services</Link>
          </motion.button>
        </motion.div>
      </motion.div>

      {/* Left/Right Navigation Buttons */}
      <motion.div
        className="absolute bottom-24 sm:bottom-12 left-4 sm:left-6 lg:left-20 flex items-center space-x-2 sm:space-x-4 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <motion.button
          onClick={handlePrevious}
          className="p-1 sm:p-2 rounded-full bg-white hover:bg-opacity-90 focus:outline-none"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          aria-label="Previous Slide"
        >
          <ChevronLeft size={20} color='black'/>
        </motion.button>
        <motion.span className="text-base sm:text-lg font-semibold">
          {currentIndex + 1}/{slides.length}
        </motion.span>
        <motion.button
          onClick={handleNext}
          className="p-1 sm:p-2 rounded-full bg-white hover:bg-opacity-90 focus:outline-none"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          aria-label="Next Slide"
        >
          <ChevronRight size={20} color='black'/>
        </motion.button>
      </motion.div>

    </motion.section>
  );
};

export default Hero;
