import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import { Menu, X, Calendar } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import ContactForm from './ContactForm'; // Assuming you have a ContactForm component

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const location = useLocation(); // Get current path

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false); // Function to close the menu
  const toggleContactForm = () => setShowContactForm(!showContactForm);

  // Header animation variants
  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeInOut' } },
  };

  const navItemVariants = {
    hover: { scale: 1.1, color: '#FBBF24', transition: { type: 'spring', stiffness: 300 } },
    tap: { scale: 0.95 },
  };

  // Contact form modal animation
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.3, ease: 'easeInOut' } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3, ease: 'easeInOut' } },
  };

  // Function to check if a link is active
  const isActive = (path) => location.pathname === path;

  return (
    <>
      <motion.header
        className="fixed w-full z-50 bg-[#5542C1] text-white shadow-lg font-poppins"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        <div className="container mx-auto flex flex-wrap items-center justify-between py-5 px-6 md:px-8">
          <motion.div
            className="flex items-center"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: 'spring', stiffness: 400 }}
          >
            <Link to="/" className="text-white font-grotesk text-2xl lg:text-3xl font-bold capitalize tracking-wider">
              Inernett
            </Link>
          </motion.div>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex space-x-8 xl:space-x-16 text-white">
            {['About', 'Products', 'Services', 'Insights'].map((item) => {
              const path = item === 'About' ? '/about' : `/${item.toLowerCase()}`;
              return (
                <motion.div
                  key={item}
                  variants={navItemVariants}
                  whileHover="hover"
                  whileTap="tap"
                >
                  <Link
                    to={path}
                    className={`text-base font-poppins font-light tracking-wide hover:underline underline-offset-8 uppercase ${
                      isActive(path) ? 'text-[#FFFC31] underline' : 'text-white'
                    }`}
                  >
                    {item}
                  </Link>
                </motion.div>
              );
            })}
          </nav>

          {/* Mobile and Tablet Menu Button */}
          <motion.div
            className="lg:hidden  text-[#FFFC31] pr-1 pt-2"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <button onClick={toggleMenu}>
              {isOpen ? <X size={30} /> : <Menu size={30} />}
            </button>
          </motion.div>

          {/* Consult Us Button (Visible on Medium and Above Screens) */}
          <motion.button
            onClick={toggleContactForm}
            className="hidden lg:flex items-center bg-[#FFFC31] text-black px-5 py-2 rounded-lg hover:bg-[#FFFC31] transition duration-300 font-poppins tracking-normal uppercase"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Calendar className="mr-2" size={18} />
            Contact Us
          </motion.button>
        </div>

        {/* Mobile and Tablet Navigation */}
        <AnimatePresence>
          {isOpen && (
            <motion.nav
              className="lg:hidden flex flex-col items-center bg-gray-900 text-white space-y-5 py-6 w-full"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
              {['About', 'Products', 'Services', 'Insights'].map((item) => {
                const path = item === 'About' ? '/about' : `/${item.toLowerCase()}`;
                return (
                  <motion.div
                    key={item}
                    variants={navItemVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <Link
                      to={path}
                      onClick={closeMenu} // Close the menu after clicking a link
                      className={`text-base ${isActive(path) ? 'text-blue-400 underline' : 'text-white'}`}
                    >
                      {item}
                    </Link>
                  </motion.div>
                );
              })}
              <motion.button
                onClick={() => {
                  toggleContactForm();
                  closeMenu(); // Close the menu after opening the contact form
                }}
                className="text-slate-800 bg-yellow-400 px-12 py-2 rounded-lg hover:bg-[#FFFC31] transition duration-300 font-poppins tracking-normal flex flex-row"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Calendar className="mr-2 " size={18} />
                Contact Us
              </motion.button>
            </motion.nav>
          )}
        </AnimatePresence>
      </motion.header>

      {/* Contact Form Modal */}
      <AnimatePresence>
        {showContactForm && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
          >
            <ContactForm onClose={toggleContactForm} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;
