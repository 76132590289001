import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import BestProjects from '../components/Projects';
import Services from '../components/Spage';
import LatestInsights from '../components/Latest';
import Footer from '../components/Footer';
import About from '../components/about';
import Banner from '../components/Banner';
const Service = () => {
return (
<div>
<Services />
<Banner />
</div>
 );
};
export default Service;