export const project1 = {
  id: 'lms',
  title: 'LMS',
  description: 'Learning Management System',
  img: '/lmsd.jpg',
  explanation: [
    'A Learning Management System (LMS) is a software application that enables organizations to deliver, manage, and track educational content and training programs. It serves as a centralized hub where instructors can upload materials, and learners can access courses, take assessments, and track their progress. The system automates key tasks such as grading, reporting, and certification issuance, reducing the administrative burden and ensuring that learners and instructors can focus on learning and development.',
    'An LMS also allows for custom course creation, so companies can build programs tailored to their needs, offering interactive quizzes, video content, and course assessments. The system can be used across industries such as corporate training, higher education, and professional development.',
    'Beyond basic course management, LMS platforms often support integrations with other tools like Zoom for virtual classrooms, and analytics to monitor performance and engagement. This data can be valuable for evaluating the effectiveness of training programs and making improvements.'
  ],
  benefits: [
    'Centralized repository for content and training resources',
    'Simplifies tracking of learner progress and performance',
    'Automated grading, certification, and reporting processes',
    'Customizable course creation and delivery options',
    'Supports various content formats such as video, quizzes, and interactive media'
  ],
};

export const project3 = {
  id: 'erp',
  title: 'ERP',
  description: 'Enterprise Resource Planning',
  img: '/ERPDesign.png',
  explanation: [
    'Enterprise Resource Planning (ERP) software is a powerful tool that integrates multiple business processes into one unified system. ERP systems provide real-time data visibility across key business functions such as finance, human resources, procurement, and supply chain management. This integration streamlines business operations, reduces inefficiencies, and allows for better decision-making based on accurate, real-time data.',
    'An ERP system automates many routine business tasks, which reduces the need for manual input and minimizes errors. By consolidating information from various departments, it enables seamless communication and data sharing across an organization.',
    'Additionally, ERP systems are highly customizable and can be tailored to suit the specific needs of any industry, including manufacturing, retail, healthcare, and education. This flexibility allows organizations to manage growth more effectively and stay competitive in their markets.'
  ],
  benefits: [
    'Integration of various business processes into a single system',
    'Real-time data visibility across departments for better decision-making',
    'Streamlined processes reduce manual effort and errors',
    'Automated reporting, finance tracking, and forecasting capabilities',
    'Enhances communication and collaboration across departments'
  ],
};

export const project2 = {
  id: 'pms',
  title: 'PMS',
  description: 'Project Management System',
  img: '/ProjectDev.png',
  explanation: [
    'Project Management Systems (PMS) are designed to help teams plan, organize, and execute projects more efficiently. These systems provide tools to assign tasks, set deadlines, and monitor progress, ensuring projects stay on schedule and within budget. With PMS, team leaders can allocate resources more effectively and track key deliverables.',
    'Beyond project planning, PMS software offers real-time collaboration tools, allowing team members to communicate and share updates directly within the platform. This reduces the reliance on emails and external messaging tools, keeping all project-related information centralized.',
    'PMS also offers reporting and analytics features, enabling teams to track project performance, budget usage, and overall progress. With these insights, organizations can identify potential bottlenecks early and adjust their strategy to keep projects on track.'
  ],
  benefits: [
    'Task assignment and deadline tracking',
    'Team collaboration tools like file sharing and communication',
    'Real-time project progress and reporting',
    'Supports Agile, Waterfall, and other project management methodologies',
    'Helps ensure that projects stay within scope and budget'
  ],
};


export const project4 = {
  id: 'Event',
  title: 'Event Platform',
  description: 'Content Management System',
  img: '/erp.jpg',
  explanation: 'Event platforms help streamline event planning, management, and engagement. These platforms simplify the process of organizing events and provide tools for seamless event execution.',
  benefits: [
    'Centralized event management tools',
    'Real-time updates and attendee tracking',
    'Integrated communication and feedback features',
  ],
};

export const project5 = {
  id: 'Nova',
  title: 'Celebrity',
  description: 'Celebrity Relationship Management',
  img: '/lms.png',
  explanation: 'Celebrity relationship management software helps organizations track and manage relationships with celebrities and influencers. It streamlines engagement efforts and boosts collaboration.',
  benefits: [
    'Simplified celebrity management and tracking',
    'Enhanced engagement and relationship-building tools',
    'Automated workflows for outreach and partnerships',
  ],
};

export const project6 = {
  id: 'Unico',
  title: 'E-Commerce Platform',
  description: 'Online Shopping Platform',
  img: '/pms.png',
  explanation: 'E-commerce platforms provide businesses with the tools to sell products online, manage orders, and track customer behavior. These platforms are essential for modern-day retail operations.',
  benefits: [
    'Seamless online shopping experience',
    'Comprehensive order and inventory management',
    'Advanced customer insights and analytics',
  ],
};
