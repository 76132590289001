import React from 'react';
import About from '../components/about';
import AboutHero from '../components/Abouthero';
import Banner from '../components/Banner';
import Dev from '../components/Designers';
import MarketingSection from '../components/Marketing';
import DifferenceSection from '../components/abi';
const Aboutpage = () => {
return (
<div>
<AboutHero/>
<Dev/>
<About />
<MarketingSection/>
<Banner />
</div>
 );
};
export default Aboutpage;