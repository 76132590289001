import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const socialIcons = [
    { icon: FaFacebookF, href: '#facebook', label: 'Facebook' },
    { icon: FaTwitter, href: 'https://x.com/InerNett31382', label: 'Twitter' },
    { icon: FaLinkedinIn, href: 'https://www.linkedin.com/company/inernett/', label: 'LinkedIn' },
    { icon: FaInstagram, href: 'http://instagram.com/iner_nett/', label: 'Instagram' },
  ];

  const quickLinks = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Services', href: '/services' },
    { name: 'Portfolio', href: '/portfolio' },
    { name: 'Contact', href: '/contact' },
  ];

  const contactInfo = [
    { icon: FaMapMarkerAlt, text: 'Near Century Mall, Megenagna, Addis Ababa, Ethiopia' },
    { icon: FaPhone, text: '+(251) 9-20-750006' },
    { icon: FaEnvelope, text: 'info@inernett.com' },
  ];

  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto font-poppins px-10 sm:px-8 lg:px-10 py-16 md:py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h3 className="text-3xl font-bold mb-6 font-grotesk tracking-wider">Inernett</h3>
            <p className="text-gray-300 mb-6 text-lg font-light lg:leading-relaxed font-roboto">
              Partner with our design agency for exceptional results and transformative business growth.
            </p>
            <div className="flex space-x-5">
              {socialIcons.map((social, index) => (
                <motion.a
                  key={index}
                  href={social.href}
                  className="text-[#FFFC31] hover:text-amber-400 transition-colors duration-300"
                  whileHover={{ scale: 1.3 }}
                  whileTap={{ scale: 0.8 }}
                  aria-label={social.label}
                >
                  <social.icon size={24} />
                </motion.a>
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            <h3 className="text-xl font-semibold mb-6 font-poppins">Quick Links</h3>
            <ul className="text-gray-300 space-y-3">
              {quickLinks.map((link, index) => (
                <li key={index}>
                  <motion.div
                    className="hover:text-amber-400 font-light transition-colors duration-300 text-lg font-roboto"
                    whileHover={{ x: 5 }}
                  >
                    <Link to={link.href}>
                      {link.name}
                    </Link>
                  </motion.div>
                </li>
              ))}
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h3 className="text-xl font-semibold mb-6 font-poppins">Contact Us</h3>
            <ul className="text-gray-300 space-y-3">
              {contactInfo.map((info, index) => (
                <li key={index} className="flex items-center text-lg font-roboto">
                  <info.icon className="mr-3 text-[#FFFC31]" size={20} />
                  <span className="font-light lg:leading-relaxed">{info.text}</span>
                </li>
              ))}
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <h3 className="text-xl font-semibold mb-6 font-poppins">Newsletter</h3>
            <p className="text-gray-300 mb-6 text-lg font-light lg:leading-relaxed font-roboto">
              Subscribe to our newsletter for the latest updates and special offers.
            </p>
            <form action="https://formsubmit.co/info@inernett.com" method="POST" className="flex flex-col sm:flex-row">
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="bg-white text-indigo-900 lg:w-52 px-6 py-3 rounded-t-md sm:rounded-l-md sm:rounded-t-none flex-1 mb-3 sm:mb-0"
                required
              />
              <motion.button
                className="bg-indigo-700 text-white px-5 py-3 font-poppins rounded-b-md sm:rounded-r-md sm:rounded-b-none hover:bg-amber-400 transition-colors duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
              >
                Subscribe
              </motion.button>
            </form>
          </motion.div>
        </div>
      </div>
      <div className="bg-[#5542C1] text-white py-6">
        <div className="container mx-auto font-poppins px-6 sm:px-8 lg:px-12 text-center text-white">
          &copy; {currentYear} INERNETT. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
