import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './Pages/Home';
import Services from './Pages/Services';
import Projects from './Pages/Projects';
import Insights from './Pages/Insights';
import Footer from './components/Footer';
import ProjectDetails from './components/ProjectDetails';
import ServiceDetails from './components/ServiceDetail';
import InsightDetail from './components/InsightDetail';
import About from './Pages/About';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <Router>
       <ScrollToTop/>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Projects />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/projects/:id" element={<ProjectDetails />} />
        <Route path="/services/:id" element={<ServiceDetails />} />
        <Route path="/insight/:id" element={<InsightDetail />} />

      </Routes>
      <Footer />
    </Router>
  );
};


export default App;
