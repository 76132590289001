import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const services = [
  { id: 1, title: 'Professional Web Development', description: 'We create SEO-friendly websites that are visually appealing and fully functional, helping your business stand out online.', img: 'Website.jpg' },
  { id: 2, title: 'Mobile App Development ', description: 'We build user-friendly mobile apps for iOS and Android, designed to enhance customer experiences and drive business growth.', img: 'MBD.png' },
  {
    id: 3,
    title: 'Custom Software Development',
    description: 'Our tailored software solutions seamlessly streamline your operations and foster long-term business scalability.',
    img: 'SDD.png'
  },  
  { id: 4, title: 'SEO Optimization', description: 'Boost your search engine rankings and drive organic traffic with our proven SEO strategies, ensuring better visibility and online reach.', img: 'SE.jpg' },
  { id: 5, title: 'Digital Marketing', description: 'Comprehensive digital marketing solutions designed to boost your brand and drive long-term growth through social media and content marketing.', img: 'Smm.jpg' },
  { id: 6, title: 'UI/UX Design', description: 'Creating intuitive, user-friendly interfaces that offer a seamless experience, ensuring high engagement and satisfaction for your users.', img: 'design.jpg' },
];

const Services = () => {
  return (
    <section className="bg-white font-poppins text-gray-800 py-16 sm:py-24 lg:py-16 lg:pt-44 pt-32" id="services">
      <div className="container mx-auto px-10 sm:px-6 lg:px-24">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end mb-8 sm:mb-12 lg:mb-16 text-center sm:text-left">
          <div className="max-w-2xl mb-4 sm:mb-0">
            <h2 className="text-2xl sm:text-4xl uppercase font-semibold text-gray-800 mb-0 sm:mb-0">
              Our Services
            </h2>
            {/* Add text under the heading */}
            <p className="text-gray-600 mt-4 lg:text-lg text-base capitalize">
             Explore our range of digital solutions to boost your business.
            </p>

          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-16">
          {services.map((service) => (
            <motion.div 
              key={service.id}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: service.id * 0.1 }}
              whileHover={{ scale: 1.05, boxShadow: "0 20px 40px rgba(0,0,0,0.2)" }}
              className="bg-white rounded-2xl shadow-xl overflow-hidden transition-transform transform-gpu flex flex-col h-full"
            >
              <div className="relative mb-6 overflow-hidden rounded-xl">
                <motion.img 
                  src={service.img} 
                  alt={service.title} 
                  className="w-full h-48 object-cover transition-transform duration-300 hover:scale-110" 
                  whileHover={{ scale: 1.05 }}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <Link to={`/services/${service.id}`}>
                    <button className="bg-blue-600 text-white font-bold py-3 px-6 rounded-full hover:bg-blue-700 transition-colors duration-300 shadow-md">
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
              <div className="p-6 sm:p-6 flex flex-col justify-between flex-grow">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
                  {service.title}
                </h3>
                <p className="text-gray-600 mb-6 text-sm font-light lg:text-base sm:text-lg">
                  {service.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
