import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

const ProductHero = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    setIsVisible(true);
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  const scrollToNextSection = () => {
    const nextSection = document.getElementById('next-section');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative font-poppins bg-cover bg-center h-screen" style={{ backgroundImage: "url('/LM.png')" }}>
      <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/70 flex flex-col justify-center items-center text-center text-white px-4 sm:px-8 md:px-16 lg:px-32 lg:pt-20">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold leading-normal mb-4 lg:mb-5 text-shadow-lg lg:px-20"
        >
        PRODUCTS
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-base sm:text-lg md:text-xl lg:text-xl font-light font-poppins tracking-wide mb-8 lg:mb-8 lg:px-20 lg:leading-relaxed max-w-3xl text-shadow-md capitalize lg:py-2"
        >
          Discover cutting-edge products designed to meet your business needs and exceed expectations.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row"
        >
        
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
        transition={{ duration: 0.8, delay: 0.8 }}
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
      >
        <button
          onClick={scrollToNextSection}
          className="text-white hover:text-yellow-400 transition duration-300 focus:outline-none"
          aria-label="Scroll to next section"
        >
          <ChevronDown size={40} className="animate-bounce" />
        </button>
      </motion.div>
    </section>
  );
};

export default ProductHero;
