import React from 'react';
import { motion } from 'framer-motion';
import { Camera, Users, Zap, Coffee } from 'lucide-react';

const About = () => {
  const stats = [
    { icon: <Camera size={28} />, value: '4+', label: 'Years Experience' },
    { icon: <Users size={28} />, value: '20+', label: 'Happy Clients' },
    { icon: <Zap size={28} />, value: '65+', label: 'Projects Completed' },
    { icon: <Coffee size={28} />, value: '1000+', label: 'Cups of Coffee' },
  ];

  return (
    <section className="lg:pb-40 py-0 lg:py-20 pb-28 px-6 bg-gradient-to-l from-yellow-50 lg:px-16 xl:px-24 via-gray-100 to-gray-100 text-black relative overflow-hidden font-poppins">
      {/* Wavy border at the top */}
      <svg className="absolute top-0 left-0 w-full" viewBox="0 0 1440 120" preserveAspectRatio="none">
        <path 
          fill="#ffffff" 
          d="M0,56L80,50.7C160,45,320,35,480,40C640,45,800,67,960,72C1120,77,1280,67,1360,61.3L1440,56L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        />
      </svg>

      <div className="container mx-auto px-4 sm:px-6 md:px-8  relative z-10 mt-20">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          
          {/* Text Section */}
          <motion.div 
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="lg:w-1/2 lg:pl-0"
          >
            {/* Optimized Heading for SEO */}
            <motion.h2 
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-4xl lg:text-5xl font-bold mb-8  font-poppins lg:leading-tight"
            >
              About Us
            </motion.h2>

            {/* Optimized Paragraph for SEO */}
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="text-base sm:text-base lg:text-lg leading-loose lg:leading-loose text-gray-800 mb-14 font-light font-poppins lg:pr-12"
            >
         Inernett Technology Solution is a dedicated team of expert developers, creative designers, and strategic thinkers, committed to delivering top-notch software development and innovative digital solutions. With over 4 years of experience in the industry, we specialize in transforming complex ideas into cutting-edge digital products that drive business growth.
            </motion.p>

            {/* SEO Optimized Stats Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {stats.map((stat, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.2 + index * 0.1 }}
                  className="bg-white text-indigo-900 p-4 sm:p-4 rounded-xl shadow-md flex items-center space-x-4"
                >
                  <div className="p-1 bg-indigo-100 rounded-full text-indigo-900">
                    {stat.icon}
                  </div>
                  <div>
                    <div className="font-bold text-xl sm:text-xl">{stat.value}</div>
                    <div className="text-sm">{stat.label}</div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Image Section */}
          <motion.div 
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="lg:w-96 mt-12 lg:mt-0 relative lg:pr-5"
          >
            <div className="relative w-full h-auto max-w-md mx-auto lg:max-w-none">
              <motion.img
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                src="AbIN.jpg"
                alt="Inernett Technology Solution Team"
                className="w-full h-auto rounded-lg shadow-lg"
              />
              <motion.div 
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{ duration: 0.5, delay: 0.8, type: "spring", stiffness: 200 }}
                className="absolute -bottom-6 -right-6 w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24 bg-[#FFFC31] rounded-full flex items-center justify-center text-indigo-900 font-bold text-lg sm:text-xl shadow-lg"
              >
                New!
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Wavy border at the bottom */}
      <svg className="absolute bottom-0 left-0 w-full" viewBox="0 0 1440 120" preserveAspectRatio="none">
        <path 
          fill="#ffffff" 
          d="M0,56L80,50.7C160,45,320,35,480,40C640,45,800,67,960,72C1120,77,1280,67,1360,61.3L1440,56L1440,120L1360,120C1280,120,1120,120,960,120C800,120,640,120,480,120C320,120,160,120,80,120L0,120Z"
        />
      </svg>
    </section>
  );
};

export default About;
