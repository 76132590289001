import React from 'react';
import { motion } from 'framer-motion';

const whyDifferentContent = [
  {
    title: 'Client-Centric Approach',
    description:
      'We prioritize our clients by offering personalized solutions that align with their business goals. We listen, adapt, and deliver results tailored to your needs.',
    img: '/client-approach.jpg', // Image path relative to the public folder
  },
  {
    title: 'Innovative Solutions',
    description:
      'Our team constantly explores the latest technologies and strategies to provide forward-thinking solutions that drive your business growth.',
    img: '/innovation.jpg', // Image path relative to the public folder
  },
  {
    title: 'Expert Team',
    description:
      'Our highly skilled and experienced professionals bring deep industry knowledge and technical expertise to every project.',
    img: '/expert-team.jpg', // Image path relative to the public folder
  },
];

const WhyWeAreDifferent = () => {
  return (
    <section className="text-gray-800 font-poppins py-16 sm:py-24 lg:py-10 lg:pb-10 lg:px-20 xl:px-28" id="why-different">
      <div className="container mx-auto px-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end mb-8 sm:mb-12 lg:mb-12">
          <div className="max-w-2xl mb-4 sm:mb-0">
            <h2 className="text-3xl uppercase tracking-tight sm:text-4xl font-semibold text-black mb-0 sm:mb-0">
              Why We Are Different
            </h2>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-x-12 lg:px-0 lg:py-0">
          {whyDifferentContent.map((content, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
              className="bg-gray-100 rounded-2xl shadow-xl overflow-hidden transition-transform transform-gpu hover:scale-105 hover:shadow-2xl duration-300"
            >
              {/* SEO-Optimized Alt Text */}
              <img
                src={content.img}
                alt={`Image depicting ${content.title}`}
                className="w-full h-48 sm:h-56 lg:h-64 object-cover"
              />
              <div className="p-6 sm:p-8">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3 sm:mb-4">
                  {content.title}
                </h3>
                <p className="text-gray-600 mb-4 sm:mb-6 font-poppins capitalize lg:leading-relaxed text-sm font-light lg:text-base sm:text-lg">
                  {content.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyWeAreDifferent;
