import React from 'react';
import { motion } from 'framer-motion';
import { Linkedin, Instagram } from 'lucide-react'; // Icons for social links

const designers = [
  { 
    name: 'Emanuel Giovani', 
    role: 'CEO', 
    img: '../E.jpg', 
    linkedin: 'https://www.linkedin.com/in/emmanuel-giovanni/', 
    instagram: 'https://www.instagram.com/strovoski',
    bio: 'Emanuel Giovani, as CEO, drives strategic growth and innovation while ensuring seamless operations. With a focus on business development, he leads the company toward long-term success and fosters a collaborative culture of excellence.'
  },
  { 
    name: 'Dawit Girma', 
    role: 'CTO', 
    img: '../Da.jpg', 
    linkedin: 'https://www.linkedin.com/in/dawit-girma-71bb31225/', 
    instagram: 'https://instagram.com/dave.ggtw',
    bio: 'Dawit Girma, as CTO, leads the company’s technological vision, ensuring innovation and scalability. With expertise in design and tech leadership, he drives the development of cutting-edge solutions that align with the company`s strategic goals.'
  },
  
];

const Designers = () => {
  return (
    <section className="py-20 lg:py-10 lg:pt-28 " id="designers">
      <div className="container mx-auto px-8 sm:px-6 lg:px-12 text-center">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl text-black font-bold font-poppins uppercase mb-8">
          Meet Our Team
        </h2>
        <p className="text-gray-800 leading-loose lg:leading-loose text-base sm:text-lg lg:text-lg capitalize font-poppins lg:px-80 mb-14 font-light">
          We are a group of passionate individuals driven by creativity, innovation, and a desire to transform businesses with exceptional design and strategic thinking.
        </p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 sm:gap-0 lg:gap-0">
          {designers.map((designer, index) => (
            <motion.div
              key={index}
              className="relative bg-neutral-100 max-w-md mx-auto rounded-lg shadow-lg overflow-hidden group transform transition-transform duration-300 hover:scale-105"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              {/* Designer Image */}
              <div className="relative overflow-hidden rounded-t-lg">
                <img
                  src={designer.img}
                  alt={`Portrait of ${designer.name}, ${designer.role}`}
                  className="w-full h-72 object-cover rounded-t-lg group-hover:opacity-90 transition duration-300"
                />
                {/* Social Links Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center space-x-6">
                  <a 
                    href={designer.linkedin} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-white hover:text-blue-400 transition duration-300"
                    aria-label={`LinkedIn profile of ${designer.name}`}
                  >
                    <Linkedin size={28} />
                  </a>
                  <a 
                    href={designer.instagram} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-white hover:text-pink-400 transition duration-300"
                    aria-label={`Instagram profile of ${designer.name}`}
                  >
                    <Instagram size={28} />
                  </a>
                </div>
              </div>

              {/* Designer Info */}
              <div className="p-5 text-center">
                <p className="text-gray-500 text-sm sm:text-base mb-2 font-mont">{designer.role}</p>
                <h3 className="text-xl sm:text-2xl font-bold text-gray-900  group-hover:text-blue-600 transition duration-300 font-poppins mb-4">
                  {designer.name}
                </h3>
                <p className="text-gray-600 text-sm sm:text-base font-poppins capitalize lg:leading-loose leading-relaxed font-light group-hover:text-gray-800 transition duration-300">
                  {designer.bio}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Designers;
