import React from 'react';
import { useParams, Link } from 'react-router-dom';

const insights = [
  { 
    id: 1, 
    title: 'Top 5 AI Tools Revolutionizing UI/UX Design in 2024', 
    img: '/ai-design-tools.jpg', 
    content: [
      'In 2024, AI is transforming the world of UI/UX design, offering tools that can handle repetitive tasks and improve creative processes. One of the major ways AI contributes is by generating design suggestions based on user input, enabling designers to explore new ideas rapidly. Tools like Figma AI and Uizard help streamline workflows by automatically generating design components, cutting down the time spent on mundane tasks like wireframing and layout creation.',
      'Another groundbreaking feature of these AI tools is their ability to automate visual improvements. Tools like Designify and Remove.bg allow designers to clean up images, remove backgrounds, and enhance visual elements instantly, making photo editing more accessible even for non-experts. This frees up more time for designers to focus on the creative side of projects while ensuring high-quality outputs.',
      'Finally, DALL·E, an AI that generates images from text descriptions, offers a new frontier for creativity. It opens up possibilities for creating unique visuals, enabling designers to quickly test concepts and bring their ideas to life. By incorporating AI into the design process, the future of UI/UX design promises to be more efficient, innovative, and adaptive to user needs.'
    ]
  },
  { 
    id: 2, 
    title: 'How Quantum Computing is Set to Change Software Development', 
    img: '/quantum-computing.jpg', 
    content: [
      'Quantum computing is on the verge of revolutionizing the software development industry by offering unprecedented processing power. Traditional computing has limitations when dealing with complex problems involving massive datasets, but quantum computing can process information exponentially faster. This opens up possibilities for solving problems that were previously deemed impossible due to the constraints of classical computing.',
      'One of the primary areas where quantum computing will make an impact is in optimization algorithms. Industries like logistics, finance, and healthcare will be able to optimize systems more efficiently by leveraging quantum algorithms to analyze millions of variables simultaneously. For developers, this means creating applications that can handle large-scale optimizations in real-time, resulting in more efficient systems and solutions.',
      'Quantum computing will also bring advancements in fields like artificial intelligence and cryptography. The immense processing power of quantum computers can improve AI training models, making them faster and more accurate. In cryptography, quantum computers could challenge existing encryption methods, leading to the development of more secure protocols that ensure data protection in an increasingly digital world.'
    ]
  },
  { 
    id: 3, 
    title: 'The Future of Web Development: What to Expect in 2024', 
    img: '/future-web-dev.jpg', 
    content: [
      'The future of web development in 2024 is being shaped by several cutting-edge technologies and trends. One of the key advancements is the rise of WebAssembly (Wasm), which enables high-performance applications to run in browsers. This technology allows developers to create more complex applications that are faster and more efficient, providing a better user experience for web-based applications that once required desktop-level computing power.',
      'Serverless architecture is another trend set to dominate the development landscape. By abstracting away the need for server management, developers can focus on writing code while cloud providers handle the scaling, maintenance, and security aspects. This model not only reduces costs but also increases the agility of development teams, allowing for faster deployment and easier scaling of web applications.',
      'Another significant trend is the increasing adoption of Progressive Web Apps (PWAs). PWAs combine the best of web and mobile applications, offering offline capabilities, push notifications, and enhanced performance. In 2024, the web will become more seamless, with PWAs providing near-native app experiences directly from browsers. Coupled with AI-driven development and Jamstack architecture, web development is set to provide faster, more secure, and more dynamic user experiences.'
    ]
  },
];

const InsightDetail = () => {
  const { id } = useParams();
  const insight = insights.find(insight => insight.id === parseInt(id));

  if (!insight) {
    return <div className="text-center text-gray-600 text-lg">Insight not found</div>;
  }

  return (
    <section className="py-32 pb-20 lg:py-32 font-poppins bg-gray-50 lg:pt-44 lg:px-32">
      <div className="container mx-auto px-4 md:px-8 lg:px-28">
        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          <img
            src={insight.img}
            alt={insight.title}
            className="w-full h-80 object-cover"
          />
          <div className="p-6 space-y-6">
            <h2 className="text-xl lg:text-3xl font-bold text-gray-800 mb-4">{insight.title}</h2>
            {insight.content.map((paragraph, index) => (
              <p key={index} className="text-gray-700 mb-4 leading-relaxed">{paragraph}</p>
            ))}
          </div>
        </div>
        <div className="mt-8 text-center">
          <Link 
            to="/insights" 
            className="inline-block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition"
          >
            Back to Insights
          </Link>
        </div>
      </div>
    </section>
  );
};

export default InsightDetail;
