import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import BestProjects from '../components/Projects';
import Services from '../components/Services';
import LatestInsights from '../components/Latest';
import Footer from '../components/Footer';
import About from '../components/about';
import Banner from '../components/Banner';
import Projects from '../components/OurProjects';
import Designers from '../components/Designers';
import DifferenceSection from '../components/abi';
import MarketingSection from '../components/Marketing';
const Home = () => {
return (
<div>
<Hero />

<Services/>
<About />
<Projects/>

<Banner />

</div>
 );
};
export default Home;