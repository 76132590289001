// src/pages/Projects.js
import React from 'react';
import { motion } from 'framer-motion';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link for navigation

const projects = [
  { id: 'lms', title: 'LMS', description: 'Learning Management System', img: '/LearningDev.png' },
  { id: 'pms', title: 'PMS', description: 'Project Management System', img: '/ProjectDev.png' },
  { id: 'erp', title: 'ERP', description: 'Enterprise Management System', img: '/ERPDesign.png' },
  //{ id: 'Event', title: 'Event', description: 'Event Relationship Management', img: '/erp.jpg' },
  //{ id: 'Nova', title: 'Celebrity', description: 'Celebrity Management System', img: '/pms.png' },
  //{ id: 'Unico', title: 'E-commerce', description: 'Online Shopping Platform', img: '/lms.png' },
];

const Projects = () => {
  return (
    <section className="bg-white font-poppins text-gray-800 py-16 sm:py-24 lg:py-16 lg:pt-24" id="projects">
      <div className="container mx-auto px-8 sm:px-6 xl:px-24">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end mb-8 sm:mb-12 lg:mb-12">
          <div className="max-w-2xl mb-4 sm:mb-0">
            <h2 className="text-2xl sm:text-4xl uppercase font-semibold text-gray-800 mb-0 sm:mb-0">
              Our Products
            </h2>
            <p className="text-gray-600 mt-4 lg:text-lg text-base capitalize">
             Check out our successful products.
            </p>  
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-12">
          {projects.map((project, index) => (
            <Link
              key={index}
              to={`/projects/${project.id}`}
              className="bg-white rounded-2xl shadow-xl overflow-hidden transition-transform transform-gpu duration-300"
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                whileHover={{ scale: 1.05, boxShadow: "0 20px 40px rgba(0,0,0,0.2)" }}
                className="flex flex-col"
              >
                <img
                  src={project.img}
                  alt={project.title}
                  className="w-full h-48 sm:h-56 lg:h-64 object-cover"
                />
                <div className="p-6 sm:p-8">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3 sm:mb-4">
                    {project.title}
                  </h3>
                  <p className="text-gray-600 mb-4 sm:mb-6 text-sm font-light lg:text-base sm:text-lg">
                    {project.description}
                  </p>
                 
                </div>
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
