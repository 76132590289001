import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ContactForm = ({ onClose }) => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [errors, setErrors] = useState({ name: '', email: '', message: '' });

    const validateForm = () => {
        let valid = true;
        const newErrors = { name: '', email: '', message: '' };
        if (!formData.name) {
            newErrors.name = 'Name is required';
            valid = false;
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
            valid = false;
        }
        if (!formData.message) {
            newErrors.message = 'Message is required';
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50 px-4"
        >
            <form
                action="https://formsubmit.co/info@inernett.com"
                method="POST"
                className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 sm:p-8 mx-4 sm:mx-0"
            >
                {/* Add hidden fields */}
                <input type="hidden" name="_subject" value="New Contact Form Submission" />
                <input type="hidden" name="_next" value="https://inernett.com" />

                <div className="flex justify-between items-center mb-4 sm:mb-6">
                    <h2 className="text-lg sm:text-xl font-semibold uppercase">Contact Us</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-2xl sm:text-3xl">
                        &#x2716;
                    </button>
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`w-full p-3 mt-1 rounded-lg border ${
                            errors.name ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    />
                    {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full p-3 mt-1 rounded-lg border ${
                            errors.email ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                </div>

                <div className="mb-4 sm:mb-6">
                    <label className="block text-sm font-medium text-gray-700">Message</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className={`w-full p-3 mt-1 rounded-lg border ${
                            errors.message ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none`}
                        rows="4"
                    />
                    {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                </div>

                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    className="w-full flex justify-center items-center p-3 rounded-lg text-white bg-blue-600 hover:bg-blue-700"
                >
                    Send Message
                </motion.button>
            </form>
        </motion.div>
    );
};

export default ContactForm;
