import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const insights = [
  { 
    id: 1, 
    title: 'Top 5 AI Tools Revolutionizing UI/UX Design in 2024', 
    description: 'Discover how AI is reshaping the design landscape with cutting-edge tools and trends you need to know in 2024.',
    img: '/ai-design-tools.jpg' 
  },
  { 
    id: 2, 
    title: 'How Quantum Computing is Set to Change Software Development', 
    description: 'Quantum computing is no longer science fiction. Learn how this emerging technology will impact software development workflows.',
    img: '/quantum-computing.jpg' 
  },
  { 
    id: 3, 
    title: 'The Future of Web Development: What to Expect in 2024', 
    description: 'Web development is evolving rapidly. Stay ahead with insights on the top trends driving the future of web technology.',
    img: '/future-web-dev.jpg' 
  },
];

const LatestInsights = () => {
  return (
    <section className="py-32 lg:py-16 font-poppins bg-gradient-to-b from-gray-50 to-white lg:pt-40 lg:px-20" id="insights">
      <div className="container mx-auto px-10 md:px-8 lg:px-24 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <h2 className="text-3xl md:text-4xl font-semibold mb-4 text-gray-800 uppercase">
            Latest Insights
          </h2>
          <p className="lg:text-lg text-sm leading-relaxed  capitalize text-gray-600 mb-12 mt-5 max-w-2xl mx-auto">
            Stay ahead of the curve with the most current tech trends and updates in the world of AI, quantum computing, and web development.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-14">
          {insights.map((insight, index) => (
            <motion.div
              key={insight.id}
              className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <Link to={`/insight/${insight.id}`}>
                <div className="relative">
                  <img
                    src={insight.img}
                    alt={insight.title}
                    className="w-full h-56 object-cover transition-transform duration-300 transform hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                    <motion.button
                      className="bg-white text-gray-800 px-5 py-2 rounded-full font-medium hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      aria-label={`Read more about ${insight.title}`}
                    >
                      Read More
                    </motion.button>
                  </div>
                </div>

                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-800 mb-2">{insight.title}</h3>
                  <p className="text-gray-600 text-sm mb-4">
                    {`${insight.description.slice(0, 100)}...`}
                  </p>
                  <Link to={`/insight/${insight.id}`} className="text-blue-600 hover:underline">
                    Continue Reading →
                  </Link>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LatestInsights;
